.copyright .nav-link {
  display: contents;
}
.card-header-sp img {
  max-width: 120px;
}

.enroll-security-key-page {
  .card-header-sp img {
    max-width: 120px;
  }
}